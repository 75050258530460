import { OktaAuthOptions } from '@okta/okta-auth-js';

export const config = {
  env: process.env.REACT_APP_ENV,
  apiUrl: process.env.REACT_APP_API_URL,
  searchAPIUrl: process.env.REACT_APP_SEARCH_API_URL,
  searchAPIKey: process.env.REACT_APP_SEARCH_API_KEY,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  sentryRelease: process.env.REACT_APP_SENTRY_RELEASE,
  oktaDomain: process.env.REACT_APP_OKTA_DOMAIN,
  oktaClientId: process.env.REACT_APP_OKTA_CLIENT_ID,
};

export const oktaConfig: OktaAuthOptions = {
  issuer: `https://${config.oktaDomain}/oauth2/default`,
  clientId: config.oktaClientId,
  redirectUri: `${window.location.origin}/login/callback`,
  postLogoutRedirectUri: `${window.location.origin}/login`,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
};
