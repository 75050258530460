import React, { useState, useEffect } from 'react';
import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { DeleteIcon, AddIcon } from '@chakra-ui/icons';

import {
  fetchTopProducts,
  addTopProductsList,
  updateTopProductsList,
  removeProductFromList,
  removeTopProductsList,
  TopProduct,
  TopProducts,
} from '../../utils/fetchTopProducts';

const SettingsTopProductsActions: React.FC<{ onRemove: () => void }> = ({
  onRemove,
}) => (
  <IconButton
    onClick={onRemove}
    aria-label="remove"
    icon={<DeleteIcon />}
    variant="ghost"
  />
);

export const SettingsTopProducts: React.FC = () => {
  const {
    isOpen: isProductModalOpen,
    onOpen: onProductOpen,
    onClose: onProductClose,
  } = useDisclosure();
  const {
    isOpen: isListModalOpen,
    onOpen: onListModalOpen,
    onClose: onListModalClose,
  } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure(); // for delete confirmation modal

  const [tabIndex, setTabIndex] = useState(0);
  const [newProduct, setNewProduct] = useState<TopProduct>({
    countryId: '',
    priority: 0,
    query: '',
    category: '',
    merchant: '',
    brand: '',
    comment: '',
    createdAt: new Date().toISOString(),
  });
  const [newListId, setNewListId] = useState('');
  const [lists, setLists] = useState<TopProducts[]>([]);

  // Validation state
  const [errors, setErrors] = useState({
    countryId: '',
    priority: '',
    query: '',
  });
  // Fetch all TopProducts on component mount
  useEffect(() => {
    fetchTopProducts()
      .then((data) => setLists(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  // Add new list
  const handleAddNewList = async () => {
    const newTab: TopProducts = { id: newListId, topProducts: [] };
    setLists([...lists, newTab]);
    setNewListId('');
    onListModalClose();

    try {
      await addTopProductsList(newTab);
    } catch (error) {
      console.error('Error adding new list:', error);
    }

    setTabIndex(lists.length); // Switch to new tab
  };

  // Validate form inputs
  const validateForm = () => {
    const newErrors = { countryId: '', priority: '', query: '' };
    if (!newProduct.countryId || newProduct.countryId.length !== 2) {
      newErrors.countryId = 'Country ID must be exactly 2 letters.';
    }
    if (!newProduct.priority || newProduct.priority < 0) {
      newErrors.priority = 'Priority must be a positive number.';
    }
    if (!newProduct.query.trim()) {
      newErrors.query = 'Query is required.';
    }
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  // Add new product to current list
  const handleAddNewProduct = async () => {
    if (!validateForm()) {
      return;
    }

    const productToSubmit: TopProduct = {
      ...newProduct,
      category: newProduct.category === '' ? undefined : newProduct.category,
      merchant: newProduct.merchant === '' ? undefined : newProduct.merchant,
      brand: newProduct.brand === '' ? undefined : newProduct.brand,
    };

    const updatedLists = [...lists];
    const updatedList = {
      ...updatedLists[tabIndex],
      topProducts: [...updatedLists[tabIndex].topProducts, productToSubmit],
    };

    updatedLists[tabIndex] = updatedList;
    setLists(updatedLists);

    try {
      await updateTopProductsList(updatedList);
    } catch (error) {
      console.error('Error adding product:', error);
    }

    setNewProduct({
      countryId: '',
      priority: 0,
      query: '',
      category: '',
      merchant: '',
      brand: '',
      comment: '',
      createdAt: new Date().toISOString(),
    });
    onProductClose();
  };

  const handleRemoveProduct = async (product: TopProduct) => {
    const updatedList = lists[tabIndex];
    try {
      await removeProductFromList(updatedList, product);
      setLists((prevLists) => {
        const newLists = [...prevLists];
        newLists[tabIndex] = {
          ...updatedList,
          topProducts: updatedList.topProducts.filter((p) => p !== product),
        };
        return newLists;
      });
    } catch (error) {
      console.error('Error removing product:', error);
    }
  };

  // Handle delete list button click (opens confirmation modal)
  const handleRemoveList = () => {
    onConfirmOpen();
  };

  // Confirm delete list
  const handleConfirmRemoveList = async () => {
    const listId = lists[tabIndex].id;

    try {
      await removeTopProductsList(listId);
      setLists((prevLists) => prevLists.filter((list) => list.id !== listId));
      setTabIndex((prevTabIndex) => (prevTabIndex > 0 ? prevTabIndex - 1 : 0)); // Move to the previous tab if possible
    } catch (error) {
      console.error('Error deleting list:', error);
    }

    onConfirmClose();
  };

  return (
    <Box data-testid="top-products-section" width="100%" pt={4} px={6} h="100%">
      {/* Tabs for each list */}
      <Tabs
        index={tabIndex}
        onChange={(index) => setTabIndex(index)}
        isLazy
        h="100%"
        d="flex"
        flexDir="column"
      >
        <TabList data-testid="top-products-tab-list">
          {lists.map((product) => (
            <Tab key={product.id}>{`ID ${product.id}`}</Tab>
          ))}
          {/* Add "+" button at the end of tabs */}
          <IconButton
            aria-label="Add new list"
            icon={<AddIcon />}
            onClick={onListModalOpen}
            variant="ghost"
            ml={2}
          />
        </TabList>
        <TabPanels display="contents">
          {lists.map((list, index) => (
            <TabPanel
              key={list.id}
              data-testid={`top-products-tab-panel-${list.id}`}
            >
              {/* Always display the top products table */}
              <Table title="Top Products">
                <Thead>
                  <Tr>
                    <Th>Country ID</Th>
                    <Th>Priority</Th>
                    <Th>Query</Th>
                    <Th>Category</Th>
                    <Th>Merchant</Th>
                    <Th>Brand</Th>
                    <Th>Comment</Th>
                    <Th>Created At</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody data-testid="top-products-table-body">
                  {list.topProducts.map((product, productIndex) => (
                    <Tr key={productIndex} title="Top Product">
                      <Td>{product.countryId}</Td>
                      <Td>{product.priority}</Td>
                      <Td>{product.query}</Td>
                      <Td>{product.category || 'N/A'}</Td>
                      <Td>{product.merchant || 'N/A'}</Td>
                      <Td>{product.brand || 'N/A'}</Td>
                      <Td>{product.comment}</Td>
                      <Td>{product.createdAt}</Td>
                      <Td whiteSpace="nowrap">
                        <SettingsTopProductsActions
                          onRemove={() => handleRemoveProduct(product)}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Box mt={4}>
                <Button onClick={onProductOpen} mr={4}>
                  Add New Top Product
                </Button>
                <Button colorScheme="red" onClick={handleRemoveList}>
                  Remove This List
                </Button>
              </Box>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      {/* Modal for adding a new list */}
      <Modal isOpen={isListModalOpen} onClose={onListModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New List</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>List ID</FormLabel>
              <Input
                placeholder="Enter new list ID"
                value={newListId}
                onChange={(e) => setNewListId(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={handleAddNewList}
              disabled={!newListId}
            >
              Add List
            </Button>
            <Button variant="ghost" onClick={onListModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for adding a new top product */}
      <Modal isOpen={isProductModalOpen} onClose={onProductClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Top Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isInvalid={!!errors.countryId}>
              <FormLabel>Country ID</FormLabel>
              <Input
                placeholder="Country ID"
                value={newProduct.countryId}
                onChange={(e) =>
                  setNewProduct({ ...newProduct, countryId: e.target.value })
                }
              />
              {errors.countryId && (
                <FormErrorMessage>{errors.countryId}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={!!errors.priority}>
              <FormLabel>Priority</FormLabel>
              <Input
                type="number"
                placeholder="Priority"
                value={newProduct.priority}
                onChange={(e) =>
                  setNewProduct({
                    ...newProduct,
                    priority: parseInt(e.target.value, 10),
                  })
                }
              />
              {errors.priority && (
                <FormErrorMessage>{errors.priority}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={!!errors.query}>
              <FormLabel>Query</FormLabel>
              <Input
                placeholder="Query"
                value={newProduct.query}
                onChange={(e) =>
                  setNewProduct({ ...newProduct, query: e.target.value })
                }
              />
              {errors.query && (
                <FormErrorMessage>{errors.query}</FormErrorMessage>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Category</FormLabel>
              <Input
                placeholder="Category"
                value={newProduct.category}
                onChange={(e) =>
                  setNewProduct({ ...newProduct, category: e.target.value })
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Merchant</FormLabel>
              <Input
                placeholder="Merchant"
                value={newProduct.merchant}
                onChange={(e) =>
                  setNewProduct({ ...newProduct, merchant: e.target.value })
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Brand</FormLabel>
              <Input
                placeholder="Brand"
                value={newProduct.brand}
                onChange={(e) =>
                  setNewProduct({ ...newProduct, brand: e.target.value })
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Comment</FormLabel>
              <Input
                placeholder="Comment"
                value={newProduct.comment}
                onChange={(e) =>
                  setNewProduct({ ...newProduct, comment: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleAddNewProduct}>
              Add Product
            </Button>
            <Button variant="ghost" onClick={onProductClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Confirmation Modal for removing a list */}
      <Modal isOpen={isConfirmOpen} onClose={onConfirmClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to delete this list? This action cannot be
              undone.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={handleConfirmRemoveList} mr={3}>
              Yes, Delete
            </Button>
            <Button variant="ghost" onClick={onConfirmClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
