import { config } from './config';

export type TopProduct = {
  countryId: string;
  priority: number;
  query: string;
  category?: string;
  merchant?: string;
  brand?: string;
  comment: string;
  createdAt: string;
};

export type TopProducts = {
  id: string;
  topProducts: TopProduct[];
};

const API_KEY = config.searchAPIKey!;
const API_URL = `${config.searchAPIUrl!}/top-products`;

// Fetch all TopProducts
export const fetchTopProducts = async (): Promise<TopProducts[]> => {
  const response = await fetch(API_URL, {
    headers: {
      'api-key': API_KEY,
    },
  });
  if (!response.ok) {
    throw new Error('Unauthorized or failed request');
  }
  return response.json();
};

// Add a new TopProducts list
export const addTopProductsList = async (
  topProducts: TopProducts,
): Promise<void> => {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'api-key': API_KEY,
    },
    body: JSON.stringify(topProducts),
  });
  if (!response.ok) {
    throw new Error('Unauthorized or failed request');
  }
};

// Update a TopProducts list
export const updateTopProductsList = async (
  topProducts: TopProducts,
): Promise<void> => {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'api-key': API_KEY,
    },
    body: JSON.stringify(topProducts),
  });
  if (!response.ok) {
    throw new Error('Unauthorized or failed request');
  }
};

// Remove a product from the list and update the backend
export const removeProductFromList = async (
  topProducts: TopProducts,
  productToRemove: TopProduct,
): Promise<void> => {
  const updatedTopProducts = {
    ...topProducts,
    topProducts: topProducts.topProducts.filter(
      (product) => product !== productToRemove,
    ),
  };
  await updateTopProductsList(updatedTopProducts);
};

// Remove an entire TopProducts list by ID
export const removeTopProductsList = async (listId: string): Promise<void> => {
  const response = await fetch(`${API_URL}/${listId}`, {
    method: 'DELETE',
    headers: {
      'api-key': API_KEY,
    },
  });
  if (!response.ok) {
    throw new Error('Failed to delete list');
  }
};
